<template>
  <article>
    <h1>회사소개</h1>
    <ul class="gnb">
      <router-link to="/sub1_a" exact-active-class="active" tag="li" class="on">회사소개</router-link>
      <router-link to="/sub1_b" exact-active-class="active" tag="li">연혁</router-link>
      <router-link to="/sub1_c" exact-active-class="active" tag="li">구성원</router-link>
      <router-link to="/sub1_d" exact-active-class="active" tag="li">오시는 길</router-link>
    </ul>
    <section class="sub1_a">
      <div class="sub1_a1">
        <h2><strong>Map</strong>+<strong>Data</strong>+<strong>Visualizing</strong></h2>
        <h2>+<strong>Technology</strong>+<strong>Platform</strong></h2>
        <p>Data 중심의 현대 사회에 고객의 합리적 의사 결정을 위해<br>
        전문적 기술을 활용한 솔루션을 제공합니다.<br><br>
        공간 정보에 기반한 빅데이터 대시보드<br>현장 업무를 지원하는 모바일 서비스<br>업무 효율성을 위한 포털형 웹서비스<br>모든 것을 아우르는 플랫폼까지<br><br>
        혁신을 위한 IT 환경. <strong>MapMind</strong>가 제공합니다.</p>
      </div>
      <div class="sub1_a2">
        <div class="culture">
          <h2>기업 문화</h2>
          <div class="line"></div>
          <div class="txt_box">
            <div class="boxa">
              <div> </div>
              <img src="/img/icon.png" alt="">
              <p>열심히 하면 잘하게 되고,<br>잘하면 좋아하게 된다.</p>
            </div>
            <div class="boxb">
              <div> </div>
              <img src="/img/icon.png" alt="">
              <p>빨리 할 일과<br>꼼꼼히 할 일을 구분하자.</p>
            </div>
            <div class="boxc">
              <div> </div>
              <img src="/img/icon.png" alt="">
              <p>키보드에 손을 올리기 전에<br>생각을 하고 펜을 들자.</p>
            </div>
            <div class="boxd">
              <div> </div>
              <img src="/img/icon.png" alt="">
              <p>자유롭다는 것은<br>마음대로 한다는 것이 아니다.</p>
            </div>
            <div class="boxe">
              <div> </div>
              <img src="/img/icon.png" alt="">
              <p>자만하지 말고 자신감 있게 하자.</p>
            </div>
          </div>
        </div>
        <div class="icon_bg">
          <div class="hide_bg">
          </div>
          <div class="icon">
            <div class="txt1"><img src="/img/sub1_icon1.png" alt=""><p>Confidence</p></div>
            <div class="txt2"><img src="/img/sub1_icon2.png" alt=""><p>Freedom</p></div>
            <div class="txt3"><img src="/img/sub1_icon3.png" alt=""><p>Trust</p></div>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>
<script>
$(document).on('scroll',window, function(){
  var sc=$(this).scrollTop();
  //회사소개글
  if(sc>370)
  $('.sub1_a1 p').css({'padding-top':'7vh','opacity':'1'});
  //기업문화
  if(sc>850)
  $('.txt_box>div>p').css({'color':'#191919','padding-top':'2vh','opacity':'1'})
   if(sc>1100)
  $('.icon_bg .hide_bg').css({'top':'0','opacity':'1'})
    //아이콘
  if(sc>1000)
  $('.icon .txt1').css({'top':'32%','opacity':'1'});
  if(sc>1100)
  $('.icon .txt2').css({'top':'32%','opacity':'1'});
  if(sc>1200)
  $('.icon .txt3').css({'top':'32%','opacity':'1'});
  
})

</script>
